import React  from 'react'
import { Card } from 'react-bootstrap'
import { Link } from "react-router-dom"

export default function Home() {

    return (
      <>
        <div className="w-100 text-center mb-2">
            <Link to="/">Home</Link>
        </div>
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">Welcome to DanLin.net</h2>
                <p className="text-center mb-2">Well, something is gonna happen here. I am just not sure what that is yet.</p>
                <Link to="/signup" className="btn btn-primary w-100 mt-3">Sign Up For A New Account</Link>
            </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
            Already have an account? <Link to="/login">Log In</Link>
        </div>

      </>
    )
}
